import {useState, useEffect} from 'react'
import {Link} from 'react-scroll'
import {Link as RouterLink, useLocation} from 'react-router-dom'
import {FaBars} from 'react-icons/fa'
import {RiArrowGoBackLine} from 'react-icons/ri'
import {CgClose} from 'react-icons/cg';
import logo from '../../img/logo.png'
import logo3 from '../../img/logo3.png'
import SideBar from './index-sidebar'
import CatMenu from './index-catmenu'
import {
    NavContainer,
    NavInner,
    LogoContainer,
    Nav,
    HomeLink,
    MobileIcon
} from './NavElements'

const MainNav = () => {

    const {pathname} = useLocation()

    const [fixNav, setFixNav] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [isHome, setIsHome] = useState(true)
    const toggle = () => {
        setIsOpen(!isOpen)
    }

    const handleFixNav = () => {

        window.scrollY > 30  ? setFixNav(true) : setFixNav(false)
        
    }

    const NavVariant = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: .5,
                delay: .1
            }
        }
    }

    useEffect(() => {

        const unsubscribe = () => {
            window.addEventListener("scroll", handleFixNav)
        }

        return unsubscribe()

    }, [])

    useEffect(() => {

        const unsubscribe = () => {
            pathname.length > 1 ? setIsHome(false) : setIsHome(true) ;
        }

        return unsubscribe()

    }, [pathname])

    return(
        <NavContainer fixed={fixNav ? true : false} variants={NavVariant} initial="hidden" animate="visible">
            <NavInner variants={NavVariant} initial="hidden" animate="visible" ishome={isHome ? true : false}>
                {
                    isHome && (
                    <LogoContainer>
                        <RouterLink to="/">
                            {
                                fixNav ?
                                <img src={logo} alt="logo" /> :
                                <img src={logo3} alt="logo" />
                            }
                        </RouterLink>
                    </LogoContainer>
                    )
                }
                {
                    !isHome && (
                        <HomeLink fixed={fixNav ? true : false} to="/">
                            <RiArrowGoBackLine />
                        </HomeLink>
                    )
                }
                <Nav fixed={fixNav ? true : false} variants={NavVariant} initial="hidden" animate="visible">    
                    <ul>
                        <CatMenu ishome={isHome} isfixed={fixNav}/>
                        {
                            isHome && (
                                <>
                                    <li><Link to="section-a" smooth={true}>Händler</Link></li>
                                    <li><Link to="about" smooth={true} offset={-50}>Über Uns</Link></li>
                                    <li><Link to="contact" smooth={true} offset={-50}>Kontakt</Link></li>
                                </>
                            )
                        }
                    </ul>
                </Nav>

                <MobileIcon onClick={toggle}>
                    {
                        !isOpen ?
                        <FaBars /> :
                        <CgClose/> 
                    }
                </MobileIcon>
                <SideBar isOpen={isOpen} toggle={toggle} isHome={isHome}/>

            </NavInner>
            
        </NavContainer>
    )
}

export default MainNav