import styled from 'styled-components/macro'
import {motion} from 'framer-motion'
import {Link} from 'react-router-dom'

export const subMenuVariants = {
    initial: {
        height: 0,
        opacity: 0,
    },
    animate: {
        height: 'auto',
        opacity: 1,
    },
    exit: {
        height: 0,
        opacity: 0,
    }
}

export const SidebarContainer = styled.aside`
position: fixed;
top: 0;
left: 0;
z-index: 990;
width: 100%;
height: 100%;
background: rgba(250,250,250,0.96);
display: flex;
justify-content: center;
align-items: center;
transition: 0.3s ease-in-out;
opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
top: ${({isOpen}) => (isOpen? '0' : '-100%')};
`

export const SideBtnWrap = styled.div`
display: flex;
justify-content: center;
`

export const SidebarWrapper = styled.div`
    color: #fff;
    height: 70vh;
    width: 100%;
    font-size: 4.5vw;

    @media screen and (max-width: 1050px) {
        font-size: 3.5vw;
    }

    @media screen and (max-width: 650px){
        font-size: 4.5vw;
    }
`

export const SidebarMenu =styled.ul`

    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 0;

li {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #2F2F2F;
    cursor: pointer;

    &:hover {
        color: #C63842;
        transition: 0.2s ease-in-out;
    }
}

a {
    display: block;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

`

export const SubMenuContainer = styled.li`
    display: none;
    flex-direction: column;

    @media screen and (max-width: 1050px) {
        display: flex;
     }
`

export const KatButton = styled.div`
    font-size: 4.5vw;

    @media screen and (max-width: 1050px) {
       font-size: 3.5vw;
    }

    @media screen and (max-width: 650px){
       font-size: 4.5vw;
    }
`

export const KatList = styled(motion.ul)`
    padding: 0;
    margin-top: 2vw;
    margin-bottom: 2vw;
    border-bottom: 1px solid black;
    overflow: hidden;
`

export const KatItem = styled(motion.li)`

`
    
export const SubMenuLink = styled(Link)`
    text-decoration: none;
    color: black;
    margin: 3vw;
    font-size: 3.5vw;

    @media screen and (max-width: 650px){
        font-size: 4.5vw;
    }
    
    &:hover {
        color: #C63842;
    }
`

export const SpielerschutzLink = styled(Link)`
    text-decoration: none;
    color: #2F2F2F;

    &:hover {
        color: #C63842;
    }
    
`