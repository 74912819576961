import styled from 'styled-components'
import {motion} from 'framer-motion'

export const Container = styled(motion.div)`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
`

export const SignupContainer = styled(motion.div)`
    width: 30vw;
    padding: 2vw;
    background: #202020;
`

export const Form = styled.form`
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const Field = styled.div`
    width: 80%;
    height: 5rem;

    input {
        height: 50%;
    }
`

export const ErrorMessage = styled.div`
    height: 50%;
`