import {useState, useEffect} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useInView} from 'react-intersection-observer'
import {useAnimation} from 'framer-motion'
import {MdPhoneAndroid, MdPlace} from 'react-icons/md'
import {HiOutlineMail} from 'react-icons/hi'
import {BiWorld} from 'react-icons/bi'
import emailjs from 'emailjs-com'
import contactImage from '../../img/contact-image.jpeg'
import {
    ContactContainer,
    ContactInner,
    ContactForm,
    Form,
    ShopDetails,
    DetailsContainer,
    ContactImage,
    InputContainer,
    Error
} from './ContactElements'

const Contact = () => {

    const {ref, inView} = useInView({
        threshold: 0.1
    })
    const animation = useAnimation()
    const [loader, setLoader] = useState(false)
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            message: ''
        },
        validationSchema: Yup.object({

            name: Yup.string()
     
              .max(15, 'Must be 15 characters or less')
     
              .required('Required'),
     
            message: Yup.string()
     
              .max(50, 'Must be 50 characters or less')
     
              .required('Required'),
     
            email: Yup.string().email('Invalid email address').required('Required'),
     
        }),
        onSubmit: values => {

            setLoader(true)

            //FOR DEBUGGING PERPOUSE
            // setTimeout(() => {
            //     setLoader(false)
            // }, 1500)

            emailjs.send("service_9x5p04b","template_25d7qrs",{
                from_name: values.name,
                message: values.message,
                reply_to: values.email,
                }, "user_XnNMFTx3QJznw4NHZmtFN")
                .then(ok => {
                    setLoader(false)
                    console.log(ok)
                })
                .catch(err => {
                    setLoader(false)
                    console.log(err)
                })
        }
    })

    useEffect(() => {

        if(inView) {
            animation.start({
                x: 0,
                transition: {
                    typw: 'tween',
                    duration: .5
                }
            }) 
        }

    // eslint-disable-next-line
    }, [inView])

    return(
        <ContactContainer name="contact">
            <ContactInner  ref={ref}>
                <ContactForm>
                    <h2>Kontakt</h2>
                    <Form onSubmit={formik.handleSubmit}>
                        <InputContainer>
                            <input 
                                type="text" 
                                id="name"
                                name="name"
                                placeholder="Name"
                                onChange={formik.handleChange}
                                value={formik.values.name} 
                            />
                            {
                                formik.errors.name && <Error>Name is required</Error>
                            }
                        </InputContainer>
                        <InputContainer>
                            <input 
                                type="email"
                                id="email"
                                name="email"
                                placeholder="E-mail"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                            />
                            {
                                formik.errors.email && <Error>Email is required</Error>
                            }
                        </InputContainer>
                        <InputContainer>
                            <textarea 
                                name="message"
                                placeholder="Message"
                                onChange={formik.handleChange}
                                value={formik.values.message}
                            />
                            {
                                formik.errors.message && <Error>Please write a message</Error>
                            }
                        </InputContainer>
                        <p>* Füllen Sie aus</p>
                        <button type="submit">
                            {
                                loader ?
                                "..." :
                                "Senden"
                            }
                        </button>
                    </Form>
                </ContactForm>
                <ShopDetails>
                    <DetailsContainer>
                        <h3>Adresse</h3>
                        <ul>
                            <li><span><MdPhoneAndroid /></span> +41 43 243 31 08</li>
                            <li><span><HiOutlineMail /></span> info@ornetwork.ch</li>
                            <li><span><BiWorld /></span> www.ornetwork.ch</li>
                            <li><span><MdPlace /></span> Kernstrasse 60, 8004 Zürich</li>
                        </ul>
                    </DetailsContainer>
                    <ContactImage 
                        src={contactImage} 
                        alt="additional image"
                        initial={{x: -210}}
                        animate={animation}
                    />
                </ShopDetails>
            </ContactInner>
        </ContactContainer>
    )
}

export default Contact