import firebase from 'firebase/app';
import '@firebase/firestore';
import 'firebase/storage';

const app = firebase.initializeApp({
    apiKey: "AIzaSyDtqiLB7axfKvnPfp_7nYIndYhApQLBQcI",
    authDomain: "ornetwork-6e34b.firebaseapp.com",
    projectId: "ornetwork-6e34b",
    storageBucket: "ornetwork-6e34b.appspot.com",
    messagingSenderId: "70246831795",
    appId: "1:70246831795:web:ffd701403611065f4902c6",
    measurementId: "G-3E1BRFTGNM"
})

export function getFirebase() {
    return app;
}

export function getFirestore() {
    return firebase.firestore(app);
}
