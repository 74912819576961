import {useState, useContext} from 'react'
import styled from 'styled-components'
import {motion, AnimatePresence} from 'framer-motion'
import Signup from './Signup'
import Login from './Login'
import Session from './Session'

const Container = styled(motion.div)`
    width: 100vw;
    height: 100vh;
    background: #2f2f2f;
`

const containerAnim = {
    initial: {
        opacity: 0
    },
    animate: {
        opacity: 1
    },
    exit: {
        opacity: 0
    }
}

const Users = () => {

    const [isRegistered, setIsRegistered] = useState(false)
    const [isLogged, setIsLogged] = useState(false)

    // Si currentUser esta vacio, renderizar Login, sino, renderizar plataforma

    return(
        <AnimatePresence>
            <Container
                variants={containerAnim}
                initial="initial"
                animate="animate"
                exit="exit  "
            >
                {
                    isLogged ?
                    <Session /> :
                    isRegistered ?
                    <Login /> : 
                    <Signup />
                }
            </Container>
        </AnimatePresence>
    )
}

export default Users