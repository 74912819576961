import React from 'react'
import styled from 'styled-components/macro'
import {motion} from 'framer-motion'
import Video from './Video'

const LandingContainer = styled(motion.section)`
    position: relative;
    width: 100%;
    height: 56vw;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @media screen and (max-width: 1050px) {
        height: 60vw;
    }

    @media screen and (max-width: 650px){
        height: 104vh;
    }
`
const LandingOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(198, 56, 66, 0.93);
`

const LandingCopy = styled.div`
    width: 65%;
    z-index: 10;

    h1 {
        font-size: 4.3vw;
    }

    h3 {
        color: white;
        font-size: 2vw;
        line-height: 2;
    }

    @media screen and (max-width: 650px){

        width: 85%;

        h1 {
            font-size: 9vw;
            text-align: center;
        }

        h3 {
            font-size: 4vw;
            text-align: center;
        }
    }
`

const Landing = () => {
    return(
        <LandingContainer name="home">
            <Video />
            <LandingOverlay />
            <LandingCopy>
                <h1>Ihr Partner für Spielautomaten</h1>
                <h3>Spielautomaten das ist Kultur, das ist Lebensstil, das ist ein Puls, den man spürt! </h3>
            </LandingCopy>
        </LandingContainer>
    )
}

export default Landing