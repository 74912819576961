import {useState, useEffect} from 'react'
import {AnimatePresence, motion} from 'framer-motion'
import {getFirestore} from '../../firebase/index-firebase'
import {
    CatLi,
    Button,
    DropDown,
    CatLink,
    SimilCatLink,
    Underlay,
    menuVariants,
    linksVariants,
    SpielerschutzContainer,
    SpielerschutzInner,
    PopupVariants
} from './CatMenuElements'

const CatMenu = (props) => {

    const db = getFirestore()
    const [isOpen, setOpen] = useState(false)
    const [popOpen, setPopOpen] = useState(false)
    const [categories, setCategories] = useState([])

    const handleOpen = () => {
        setOpen(!isOpen)
    }

    const handlePopup = () => {
        setPopOpen(!popOpen)
    }

    useEffect(() => {

        const unsubscribe = () => {

            const array = []

            db.collection('content').doc('categories').get()
            .then(item => {
                item.data().categories.forEach(item => array.push(item))
                setCategories(array)
            })

        }

        return unsubscribe()
        // eslint-disable-next-line
    }, [])

    return (
        <CatLi>
            <Button onClick={handleOpen}>Kategorien</Button>
            <AnimatePresence>
                {
                    isOpen &&
                    <>
                        <Underlay onClick={handleOpen}/>
                        <DropDown
                            variants={menuVariants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            ishome={props.ishome}
                            isfixed={props.isfixed}
                            >
                                {
                                    categories.length > 0 &&
                                    categories.map(category => {
                                        return(
                                            <AnimatePresence key={category.to}>
                                                <motion.div 
                                                    key={category.to} 
                                                    variants={linksVariants} 
                                                    whileHover={{scale: 1.1}} 
                                                    onClick={handleOpen}>
                                                    <CatLink isfixed={props.isfixed} to={category.to}>{category.name}</CatLink>
                                                </motion.div>
                                            </AnimatePresence>
                                        )
                                    })
                                }
                                {/* ADD SPECIAL ITEM TO LIST */}
                                <AnimatePresence>
                                    <motion.div 
                                        variants={linksVariants} 
                                        whileHover={{scale: 1.1}} 
                                        onClick={handleOpen}>
                                        <SimilCatLink isfixed={props.isfixed} onClick={handlePopup}>Spielerschutz</SimilCatLink>
                                    </motion.div>
                                </AnimatePresence>
                        </DropDown> 
                    </>
                }
            </AnimatePresence>
            <AnimatePresence>
                {
                    popOpen &&
                    <SpielerschutzContainer 
                        onClick={handlePopup}
                        variants={PopupVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                    >
                        <SpielerschutzInner>
                            <h2>Spielerschutz</h2>
                            <p>
                                Spielautomaten haben in der Schweiz eine lange Tradition. Bis 2005 waren Spielautomaten in Restaurants und Spielsalons weit verbreitet. Die damaligen Automaten waren eigentliche Glückspielautomaten. Der Gewinn war grösstenteils vom Zufall abhängig und der Spieler konnte das Spiel kaum beeinflussen.
                            </p>
                            <p>
                                Seit dem 1. April 2005 gibt es in der Schweiz diese Automaten nur noch in Casinos.
                            </p>
                            <p>
                                Geschicklichkeitsautomaten, die nach dem 1. April 2005 bewilligt wurden und aktuell betrieben werden, erfüllen strenge Anforderungen an die Fähigkeiten und an das Geschick der Spieler. Der Gewinn ist abhängig vom Geschick der Spieler. Unkonzentrierten Spielern ist es nicht möglich etwas zu gewinnen.
                            </p>
                            <p>
                                Die ESBK erhebt regelmässig Studien zu Glücksspiel und Spielsucht in der Schweiz. In der BASS- Studie von 2004 ist Spielsucht im Zusammenhang mit (altrechtlichen) Geschicklichkeitsautomaten noch ein Thema. In den Studien von 2009 und 2014 sind Geschicklichkeitsautomaten wegen den geringen Fallzahlen kein Thema mehr.
                            </p>
                            <p>
                                In den Kantonen, die Geschicklichkeitsautomaten erlauben oder tolerieren, sind keine sozialen Probleme wegen Geschicklichkeitsautomaten bekannt.
                            </p>
                            <p>
                                Gleichwohl verpflichtet sich Ornetwork im Rahmen der Veranstalterbewilligung durch die COMLOT ein Sozial- und Sicherheitskonzept anzuwenden. Dieses wird durch den Verband SWISSPLAY ausgearbeitet und orientiert sich am Sozialkonzept von SWISSLOS
                            </p>
                            <p>
                                Hilfreiches für Betroffene, Angehörige und Interessierte:
                            </p>
                            <div>
                                <p>https://www.sos-spielsucht.ch/de/</p>
                                <p>https://www.suchtschweiz.ch/gluecksspiel/</p>
                            </div>
                            <div>
                                <ul>
                                    <li>Helpline 0800 040 080 SOS-Spielsucht</li>
                                    <li>Sorgentelefon 143 Die Dargebotene Hand</li>
                                </ul>
                            </div>
                        </SpielerschutzInner>
                    </SpielerschutzContainer>
                }
            </AnimatePresence>
        </CatLi>
    )
}

export default CatMenu