import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {getFirestore} from '../../firebase/index-firebase'
import {AnimatePresence} from 'framer-motion'
import {
    ProductPageContainer,
    FakeNav,
    ProductPageInner,
    PageLeft,
    ProductImage,
    PageRight,
    ProductTitle,
    ProductDescription,
    IndividualProduct,
    FakeTitle,
    productsVariants,
    fakeNavVariants
} from './ProductElements'

const ProductPage = () => {

// TODO LOADER EN LINEA 110

    const db = getFirestore()
    const {product} = useParams()
    const [products, setProducts] = useState([])

    useEffect(() => {

        db.collection('products').where("category", "==", `${product}`).get()
        .then(docs => {
            
            let array = []

            docs.forEach(doc => {
                array.push(doc.data())
            })

            setProducts(array)

        })
        .catch(err => console.log(err))
        
    // eslint-disable-next-line
    }, [product])

    return(
        <ProductPageContainer>
            <AnimatePresence>
                <FakeNav variants={fakeNavVariants} initial="initial" animate="animate" exit="exit">
                    <FakeTitle
                        variants={productsVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                    >
                        {product}
                    </FakeTitle>
                </FakeNav>
            </AnimatePresence>
            <ProductPageInner>
                <AnimatePresence>
                    {
                        products.length > 0 &&
                        products.map(product => {
                            return(
                                <IndividualProduct 
                                    key={product.title}
                                    variants={productsVariants}    
                                    initial="initial"
                                    animate="animate"
                                    exit="exit"
                                >
                                    <PageLeft>
                                        <ProductImage>
                                            <img src={product.image500px} alt="imagen producto"/>
                                        </ProductImage>
                                    </PageLeft>
                                    <PageRight>
                                        <ProductTitle>{product.title}</ProductTitle>
                                        <ProductDescription>
                                            {
                                                product.description.map((item, index) => <li key={index}>{item}</li>)
                                            }
                                        </ProductDescription>
                                        <ProductDescription>
                                            {
                                                product.descriptionA !== undefined &&
                                                product.descriptionA.map((item, index) => <li key={index}>{item}</li>)
                                            }
                                        </ProductDescription>
                                        <ProductDescription>
                                            {
                                                product.descriptionB !== undefined &&
                                                <p>{product.descriptionB}</p>
                                            }
                                        </ProductDescription>
                                        <ProductDescription>
                                            {
                                                product.descriptionC !== undefined &&
                                                <p>{product.descriptionC}</p>
                                            }
                                        </ProductDescription>
                                    </PageRight>
                                </IndividualProduct>
                            )
                        })
                    }
                    {
                        products.length === 0 && <></>
                    }
                </AnimatePresence>
            </ProductPageInner>
        </ProductPageContainer>
    )
}

export default ProductPage