// SIGNIN
export const handleSignUp = (values, currentUser, app) => {
    
    const actionCodeSettings = {
        url: 'https://localhost:3000', //Cambiar a la url del sitio
        handleCodeInApp: true,
    }

    app.auth().createUserWithEmailAndPassword(values.email.trim(), values.password)
    .then(() => {
        console.log('User created')
        console.log(currentUser)
        app.auth().currentUser.sendEmailVerification(actionCodeSettings)
            .then(() => {
                console.log('Verification email sent succesfully')
            })
            .catch(err => console.log(err))

    })
    .catch(err => {
        // setError(true);
        console.log(`Failed to sign in. ${err}`)
    })
}


// LOGIN
export const handleLogin = () => {

}