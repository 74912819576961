import styled from 'styled-components/macro'

export const FooterContainer = styled.section`
width: 100%;
height: 35vw;
background-color: #2F2F2F;
`
export const FooterInner = styled.div`
width: 80%;
height: 100%;
margin: 0 auto;
display: flex;
align-items: center;
position: relative;
` 
export const FooterLeft = styled.div`
width: 50%;
${({ishome}) => !ishome && 'position: absolute'};
${({ishome}) => !ishome && 'top: 50%'};
${({ishome}) => !ishome && 'left: 50%'};
${({ishome}) => !ishome && 'transform: translateX(-50%)'};
${({ishome}) => !ishome && 'display: flex'};
${({ishome}) => !ishome && 'justify-content: center'};

img {
    width: 40%;
}

@media screen and (max-width: 650px) {
    width: 100%;
    text-align: center;
}
`
export const FooterRight = styled.div`
width: 60%;

@media screen and (max-width: 1050px) {
    width: 90%;
    font-size: 1.5vw;
}

@media screen and (max-width: 650px) {
    display: none;
}

ul {
    list-style-type: none;
    width: 80%;
    color: white;
    display: flex;
    justify-content: space-between;
}

a {
    text-decoration: none;
    transition: all .5s;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vw;

    &:hover {
        color: #C63842;
    }
}

button {
    font-family: "Raleway", "Roboto", "Oxygen", "Ubuntu", sans-serif;
    background: none;
    border: none;
    color: white;
    font-size: 1.3vw;
    text-decoration: none;
    transition: all .5s;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vw;

    &:hover {
        color: #C63842;
    }
}
`

export const Copy = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: center;
`;