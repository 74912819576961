
const MobileSpielerschutz = () => {
    return(
        <>
        <h2>Spielerschutz</h2>
        <p>
            Spielautomaten haben in der Schweiz eine lange Tradition. Bis 2005 waren Spielautomaten in Restaurants und Spielsalons weit verbreitet. Die damaligen Automaten waren eigentliche Glückspielautomaten. Der Gewinn war grösstenteils vom Zufall abhängig und der Spieler konnte das Spiel kaum beeinflussen.
        </p>
        <p>
            Seit dem 1. April 2005 gibt es in der Schweiz diese Automaten nur noch in Casinos.
        </p>
        <p>
            Geschicklichkeitsautomaten, die nach dem 1. April 2005 bewilligt wurden und aktuell betrieben werden, erfüllen strenge Anforderungen an die Fähigkeiten und an das Geschick der Spieler. Der Gewinn ist abhängig vom Geschick der Spieler. Unkonzentrierten Spielern ist es nicht möglich etwas zu gewinnen.
        </p>
        <p>
            Die ESBK erhebt regelmässig Studien zu Glücksspiel und Spielsucht in der Schweiz. In der BASS- Studie von 2004 ist Spielsucht im Zusammenhang mit (altrechtlichen) Geschicklichkeitsautomaten noch ein Thema. In den Studien von 2009 und 2014 sind Geschicklichkeitsautomaten wegen den geringen Fallzahlen kein Thema mehr.
        </p>
        <p>
            In den Kantonen, die Geschicklichkeitsautomaten erlauben oder tolerieren, sind keine sozialen Probleme wegen Geschicklichkeitsautomaten bekannt.
        </p>
        <p>
            Gleichwohl verpflichtet sich Ornetwork im Rahmen der Veranstalterbewilligung durch die COMLOT ein Sozial- und Sicherheitskonzept anzuwenden. Dieses wird durch den Verband SWISSPLAY ausgearbeitet und orientiert sich am Sozialkonzept von SWISSLOS
        </p>
        <p>
            Hilfreiches für Betroffene, Angehörige und Interessierte:
        </p>
        <div>
            <p>https://www.sos-spielsucht.ch/de/</p>
            <p>https://www.suchtschweiz.ch/gluecksspiel/</p>
        </div>
        <div>
            <ul>
                <li>Helpline 0800 040 080 SOS-Spielsucht</li>
                <li>Sorgentelefon 143 Die Dargebotene Hand</li>
            </ul>
        </div>
        </>
    )
} 

export default MobileSpielerschutz