import styled from 'styled-components/macro'
import {motion} from 'framer-motion'
import {Link} from 'react-router-dom'

export const menuVariants = {
    initial: {
        opacity: 0,
        x: -80
    },
    animate: {
        opacity: 1,
        x: 0,
        transition: {
            staggerChildren: .1
        }
    },
    exit: {
        opacity: 0,
        x: 100,
        transition: {
            duration: .3
        }
    }
}
export const linksVariants = {
    initial: {
        opacity: 0,
        x: -20
    },
    animate: {
        opacity: 1,
        x: 0
    },
    exit: {
        opacity: 0
    }
}

export const CatLi = styled.li`
    position: relative;

    ul {
        list-tyle-type: none;
    }

    a {
        text-decoration: none;
    }
`

export const Button = styled.a`
`
export const DropDown = styled(motion.div)`
    position: absolute;
    top: 4vw;
    left: ${({ishome}) => ishome ? 0 : '-7.1vw'};

`

export const CatLink = styled(Link)`
    position: relative;
    color: #202020;
    font-weight: bold;
    padding: 1vw 1vw 1vw 2.5vw !important;
    justify-content: flex-start !important;
    z-index: 888;
    background-color: ${({isfixed}) => isfixed ? 'white' : '#C63842'};
    box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.25);
`

export const SimilCatLink = styled.a`
    position: relative;
    color: #202020;
    font-weight: bold;
    padding: 1vw 1vw 1vw 2.5vw !important;
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
    cursor: pointer;
    z-index: 888;
    background-color: ${({isfixed}) => isfixed ? 'white' : '#C63842'};
    box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.25);
    border: none;
    font-weight: 900;
    transition: all .5s;

    &:hover {
        color: ${({fixed}) => fixed ? "#C63842" : "white"};
    }
`


export const Underlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 0;
`

export const SpielerschutzContainer = styled(motion.div)`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100vh;
background-color: rgba(0,0,0,0.3);
z-index: 990;
`

export const SpielerschutzInner = styled.div`
position: relative;
width: 75%;
height: 80vh;
margin: 0 auto;
top: 5vw;
padding: 1vw;
background-color: white;
overflow: scroll;
z-index: 999;
color: black;
`

export const PopupVariants = {
initial: {
    opacity: 0
},
animate: {
    opacity: 1,
    transition: {
        duration: .5
    }
},
exit: {
    opacity: 0
}
}