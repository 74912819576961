import styled from 'styled-components/macro'
import backgroundImg from '../../img/slider1.jpg'

export const Container = styled.section`
    width: 100%;
    height: 90vw;
    // margin-bottom: 10vw;
    background-color: #2F2F2F;  

    @media screen and (max-width: 1050px) {
        height: 250vw;
        // margin-bottom: 2vw;
    }

    @media screen and (max-width: 650px){
        height: 350vw;
        // margin-bottom: 3vw;
    }
`

export const Inner = styled.div`
    position: relative;
    bottom: 6vw;
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    h2 {
        position: relative;
        bottom: 6vw;
        color: white;
        font-size: 3vw;
    }
`

export const SubInner = styled.div`
    position: relative;
    bottom: 6vw;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`


export const MachinesContainer = styled.section`
    width: 100%;
    height: 50vw;
    margin-bottom: 5vw;
    background-color: #2F2F2F;  

    @media screen and (max-width: 1050px) {
        height: 50vw;
        margin-bottom: 18rem;
    }

    @media screen and (max-width: 650px){
        height: 70vw;
        margin-bottom: 20vh;
    }
`

export const MachinesInner = styled.div`
    position: relative;
    top: 16vw;
    width: 100%;
    padding-top: 1vw;
    padding-bottom: 1vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #202020;
    background: url(${backgroundImg});
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    box-shadow: 0 4px 15px 0 rgba(0,0,0,0.6);

    h2 {
        position: relative;
        top: 4rem;
        color: white;
        font-size: 3vw;
        font-weight: bold;
    }

    @media screen and (max-width: 1050px) {
        top: 0vw;
        margin-bottom: 10vw;
        background-attachment: scroll;
        background-position-y: 99%;

        h2 {
            font-size: 2rem;
            margin-top: 0;
        }
    }

    @media screen and (max-width: 650px){
        width: 100%;
        top: 0rem;

        h2 {
            font-size: 7rem;
            font-size: 1.5rem;
            color: #ca464f;
            background: rgba(250,250,250,0.6);
        }

        img {
            width: 100%;
        }
    }
`

export const MachinesImageContainer = styled.img`
    position: relative;
    top: 5rem;
    border: 10px solid #2f2f2f;
    box-shadow: 0 4px 15px 0 rgba(0,0,0,0.6);

    @media screen and (max-width: 1050px){
        top: 11rem;
    }

    @media screen and (max-width: 650px){
        top: 9rem;
        width: 100%;
    }
`