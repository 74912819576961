import {useState, useEffect} from 'react'
import {Link} from 'react-scroll'
import {useLocation} from 'react-router-dom'
import logo from '../../img/logo-red.png'
import {
    FooterContainer,
    FooterInner,
    FooterLeft,
    FooterRight,
    Copy
} from './FooterElements'

const Footer = () => {

    const {pathname} = useLocation()
    const [isHome, setIsHome] = useState(true)

    useEffect(() => {

        pathname.length > 1 ? setIsHome(false) : setIsHome(true) ;

    }, [pathname])


    return(
        <FooterContainer>
            <FooterInner>
                <FooterLeft ishome={isHome}>
                    <img src={logo} alt="footer logo"/>
                </FooterLeft>
                <FooterRight>
                    {
                        isHome &&
                        <ul>
                            <li><Link to="section-a" smooth={true}>Händler</Link></li>
                            <li><Link to="about" smooth={true}>Über Uns</Link></li>
                            <li><Link to="contact" smooth={true}>Kontakt</Link></li>
                        </ul>

                    }
                </FooterRight>
            </FooterInner>
            <Copy>2021&#169;ornetwork</Copy>
        </FooterContainer>
    )
}

export default Footer