import styled from 'styled-components/macro'
import {motion} from 'framer-motion'
import {Link} from 'react-router-dom'

export const NavContainer = styled(motion.div)`
        width: 100%;
        background-color: ${({fixed}) => fixed ? "white" : "transparent"};
        position: ${({fixed}) => fixed ? "fixed" : "absolute"};
        ${({fixed}) => fixed ? "box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.25)" : ""};
        top: 0;
        left: 0;
        z-index: 900;

        @media screen and (max-width: 1050px) {
            min-height: 6vw;
        }
    
        @media screen and (max-width: 650px){
            min-height: 10vw;
        }
`

export const NavInner = styled(motion.div)`
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: ${({ishome}) => ishome ? 'space-between' : 'flex-end'};

    @media screen and (max-width: 1050px) {
        min-height: 6vw;
        width: 85%;
    }

    @media screen and (max-width: 650px){
        min-height: 10vw;
        width: 80%;
    }
`
export const LogoContainer = styled.div`
    width: 30%;
    display: flex;
    align-items: center;
    transition: all .5s;

    img {
        width: 90%;
    }

    @media screen and (max-width: 1050px) {
        height: 3.5rem;   

        img {
            width: 45%;
        }
    }

    @media screen and (max-width: 650px){
        
        img {
            width: 100%;
            margin-top: 1vw;
        }
    }
`

export const Nav = styled(motion.nav)`
    width: 60%;
    display: flex;
    justify-content: flex-end;

    ul {
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        margin: 0;
    }

    li {
        flex: 1 0 auto;
    }

    a {
        text-decoration: none;
        padding: ${({fixed}) => fixed ? "1rem 1.5rem" : "2rem"};
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .5s;
        cursor: pointer;
        font-weight: bold;
    }

    a:hover {
        color: ${({fixed}) => fixed ? "#C63842" : "white"};
    }

    li:last-child a {
        padding-right: 0rem;
    }

    @media screen and (max-width: 1050px) {
        display: none;
    }
`

export const HomeLink = styled(Link)`
    position: absolute;
    top: 0;
    right: 0;
    color: ${({fixed}) => fixed ? "#202020" : "white"};
    background-color: ${({fixed}) => fixed ? "transparent" : "#202020"};
    font-size: 2vw;
    height: ${({fixed}) => fixed ? "4vw" : "6vw"};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1vw !important;
    transition: all .5s;

    @media screen and (max-width: 1050px) {
        height: 6vw;

        svg {
            font-size: 3.5vw;
            position: relative;
            bottom: .2vw;
        }
    }

    @media screen and (max-width: 650px){
        height: 10vw;

        svg {
            font-size: 5vw;
        }
    }
`

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 1050px) {
        min-height: 6vw;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
        cursor: pointer;
        float: left;
        position: relative;
        transition: all 1s;

        svg {
            color: black;
            z-index: 1000;
            font-size: 3.5vw;
        }
    }

    @media screen and (max-width: 650px){

        svg {
            font-size: 6vw;
        }
    }
`