import {useEffect} from 'react'
import {useAnimation, useCycle} from 'framer-motion'
import {useInView} from 'react-intersection-observer'
import {
    CardContainer,
    CardInner,
    CardImage,
    CardFront,
    CardContent,
    Title,
    Description,
    Button,
    CardBack,
    BestSeller
} from './CardElements'

const Card = ({product}) => {

    // eslint-disable-next-line
    const cardAnimation = useAnimation()
    const [flipAnimation, flip] = useCycle(
        {transform: 'rotateY(0)'},  //Acá WEBKIT transform!
        {transform: 'rotateY(180deg)'}
    )
    const buttonVariants = {
        rest: {scale: 1},
        pressed: {scale: 0.9}
    }
    const bestSellerVariants = {
        initial: {
            opacity: 0.3
        },
        animate: {
            opacity: 1,
            transition: {
                repeat: Infinity,
                repeatType: 'mirror',
                duration: .5
            }
        }
    }
    const {ref, inView} = useInView({
        threshold: 0.2
    })
    
    useEffect(() => {

        
        if(inView) {

            cardAnimation.start({
                y: 0,
                opacity: 1,
                transition: {
                    duration: .5,
                }
            })
            
        }
        // eslint-disable-next-line
    }, [inView])
    
    
    return(
        <CardContainer ref={ref} initial={{y: 100, opacity: 0}} animate={cardAnimation}>
           <CardInner animate={flipAnimation} onTap={()=>flip()}>
                <CardFront>
                    <CardImage>
                            <img src={product.image500px} alt="slot machine"/>
                    </CardImage>
                    <CardContent>
                            <Title>
                                <span>{product.title}</span> 
                                <BestSeller 
                                    variants={bestSellerVariants} 
                                    animate="animate"
                                    initial="initial"
                                >
                                    Bestseller!
                                </BestSeller>
                            </Title>
                            <Description>{product.descriptionC}</Description>
                    </CardContent>
                    <Button variants={buttonVariants} initial="rest" whileTap="pressed">
                        Mehr sehen
                    </Button>
                </CardFront>
                <CardBack>
                    <p>
                        {product.descriptionB}
                    </p>
                </CardBack>
           </CardInner>
       </CardContainer> 
    )
}

export default Card