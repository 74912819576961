import styled from 'styled-components/macro'
import sectionIcon from '../../img/section-icon.png'
import SlotAnimation from '../SlotAnimation/index-slotanimation'

const SectionA = () => {

    const SectionContainer = styled.section`
        width: 100%;
        height: 70vw;
        margin-bottom: 5vw;

        @media screen and (max-width: 1050px) {
            height: 85vw;
            margin-bottom: 5vw;
        }

        @media screen and (max-width: 650px){
            height: 100vh;
            margin-top: 7vw;
            margin-bottom: 10vw;
        }
    `

    const SectionInner = styled.div`
        width: 90%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 1050px) {
            width: 90%;
        }

    `
        
    const SectionContent = styled.div`
        width: 65%;
        text-align: center;

        @media screen and (max-width: 1050px) {
            width: 100%;
        }

        p {
            line-height: 3;
        }

        span {
            font-size: 1.6vw;
            text-transform: uppercase;

            @media screen and (max-width: 1050px) {
                font-size: 2.5vw;
            }
        }

        @media screen and (max-width: 1050px) {
            
            p {
                font-size: 2vw;
            }

            span {
                font-size: 2.5vw;
            }
        }

        @media screen and (max-width: 650px){
            
            p {
                font-size: 3.5vw;
                line-height: 2;
            }

            span {
                font-size: 4vw;
            }

        }
    `

    const Icon = styled.img`
        display: none;

        @media screen and (max-width: 1050px) {
            display: block;
            margin: 0 auto;
            width: 5vw;
        }

        @media screen and (max-width: 1050px) {
            margin: 4vw auto;
            width: 10vw;
        }
    `

    return(
        <SectionContainer name="section-a">
            <SectionInner>
                <SectionContent>
                    <p><span>Unsere Filiale</span></p>
                    <p>Wir verkaufen Geschicklichkeits- Geldspielautomaten an Veranstalter von Grossspielen mit Veranstalterbewilligung der GESPA. Gleichzeitig bieten wir selber Geschicklichkeitsautomaten in Gastronomiebetrieben und Spiellokalen an. Für Details kontaktieren Sie uns bitte mit dem Kontaktformular.</p>
                    <SlotAnimation sectionIcon={sectionIcon}/>
                    <Icon src={sectionIcon} alt="slot machine icon"/>
                    <p><span>Anmeldung</span></p>
                    <p>Suchen Sie Spielautomaten für Ihren Betrieb? Wenn Sie einen öffentlichen Betrieb führen in dem gegen Bezahlung ein Gastronomie- oder Unterhaltungsangebot zur Verfügung gestellt wird, können Sie, je nach Kanton, bis zu 2 Geschicklichkeits- Geldspielautomaten aufstellen Wenn Sie Automaten in Ihrem Betrieb aufgestellt haben möchten klicken Sie bitte hier</p>
                </SectionContent>
            </SectionInner>
        </SectionContainer>
    )
}

export default SectionA