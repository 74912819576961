import {useEffect} from 'react'
import {useAnimation} from 'framer-motion'
import {useInView} from 'react-intersection-observer'
import {AiFillPlusSquare} from 'react-icons/ai'
import {
    CardContainer,
    CardInner,
    CardImage,
    CardFront,
    CardContent,
    Title,
    Description,
    LinkButton,
    Icon
} from './CardElements'

const CardAlt = ({product}) => {

    // eslint-disable-next-line
    const cardAnimation = useAnimation()
    const {ref, inView} = useInView({
        threshold: 0.2
    })
    
    useEffect(() => {

        if(inView) {

            cardAnimation.start({
                y: 0,
                opacity: 1,
                transition: {
                    duration: .5,
                }
            })
            
        }
    // eslint-disable-next-line
    }, [inView])

    return(
       <CardContainer ref={ref} initial={{y: 100, opacity: 0}} animate={cardAnimation}>
           <CardInner>
                <CardFront>
                    <CardImage>
                            <img src={product.image500px} alt="slot machine"/>
                    </CardImage>
                    <CardContent>
                            <Title>{product.title}</Title>
                            <Description>{product.descriptionC}</Description>
                    </CardContent>
                    <LinkButton to={`${product.category}`}>
                        Mehr sehen
                    </LinkButton>
                    <Icon to={`${product.category}`}><AiFillPlusSquare /></Icon>
                </CardFront>
           </CardInner>
       </CardContainer> 
    )
}

export default CardAlt