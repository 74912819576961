import styled from 'styled-components/macro'
import {motion} from 'framer-motion'

export const fakeNavVariants = {
    initial: {
        height: 0
    },
    animate: {
        height: '6vw'
    },
    exit: {
        height: 0
    }
}

export const productsVariants = {
    initial: {
        x: 500,
        opacity: 0
    },
    animate: {
        x:0,
        opacity: 1,
        transition: {
            duration: .5
        }
    },
    exit: {
        opacity: 0
    }
}

export const FakeNav = styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #C63842;

    @media screen and (max-width: 1050px) {
        
    }

    @media screen and (max-width: 650px){
        min-height: 10vw;
    }
`

export const FakeTitle = styled(motion.p)`
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 3vw;
    height: 100%;
    margin: 0;
    margin-left: 2vw;
    color: #202020;
    text-transform: uppercase;
`

export const ProductPageContainer = styled.div`
    width: 100%;
    min-height: 50vw; 
    margin-top: 10vw;
    margin-bottom: 10vw;
    overflow: hidden;

    @media screen and (max-width: 1050px) {
        margin-top: 10vw;
    }

    @media screen and (max-width: 650px){
        margin-top: 15vw;
    }
`

export const ProductPageInner = styled.div`
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`

export const IndividualProduct = styled(motion.div)`
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 2vw;
    margin-bottom: 2vw;
    
    @media screen and (max-width: 1050px) {
        flex-direction: column;
    }

    @media screen and (max-width: 650px){
        
    }
`

export const PageLeft = styled.div`
    width: 50%;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1050px) {
        width: 100%;
        justify-content: center;
    }

    @media screen and (max-width: 650px){

    }
`   

export const PageRight = styled.div`
    width: 50%;
    padding: 1vw 2vw;
    border-radius: 25px;
    
    @media screen and (max-width: 1050px) {
        width: 70%;
        align-self: center;
    }
    
    @media screen and (max-width: 650px){
        width: 90%;
        font-size: 2.5vw;
    }
    `
    
    export const ProductImage = styled.div`
    
        img{
            max-height: 35vw;
            object-fit: cover;
        }
    `
    
    export const ProductTitle = styled.h2`
        color: white;
        background-color: #202020;
        padding: 1vw;
    `

export const ProductDescription = styled.ul`
    list-style-type: none;
    padding: 0;

    @media screen and (max-width: 650px){
        
        li {
            line-height: 1.5;        
        }
    }
`

