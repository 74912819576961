import styled from 'styled-components/macro'
import {motion} from 'framer-motion'
import {Link} from 'react-router-dom'

export const CardContainer = styled(motion.div)`
    width: 23vw;
    height: 36vw;
    background-color: transparent;
    margin: 1vw 2vw;
    perspective: 1000px;

    @media screen and (max-width: 1050px) {
        width: 35vw;
        height: 50vw;
    }

    @media screen and (max-width: 650px){
        width: 95%;
        height: 40vw;
        margin: 2vw;
    }
`

export const CardInner = styled(motion.div)`
    width: 100%;
    height: 100%;
    background-color: #202020;
    box-shadow: 0 4px 15px 0 rgba(0,0,0,0.6);
    display: flex;
    flex-direction: column;
    position: relative;
    transition: transform .6s;
    transform-style: preserve-3d;

    &:hover img {
        filter: grayscale(0);
        height: 23vw;

        @media screen and (max-width: 650px) {
            height: 100%;
        }
    }

    @media screen and (max-width: 1050px) {
        justify-content: space-between;
    }

    @media screen and (max-width: 650px){
        flex-direction: row;
    }
`
export const CardFront = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    backface-visibility: hidden;
    background-color: transparent;

    @media screen and (max-width: 650px){
        flex-direction: row;
    }
`

export const CardBack = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background-color: black;
    transform: rotateY(180deg);
    color: white;
    cursor: pointer;

    p {
        padding: 0 1.5vw;
        line-height: 1.5;
        font-size: 1.1vw;

        @media screen and (max-width: 1050px) {
            font-size: 1.7vw;
        }
    
        @media screen and (max-width: 650px){
            font-size: 2vw;
        }
    }
`


export const CardImage = styled.div`
    width: 100%;
    height: 50%;
    overflow: hidden;

    img {
        width: 100%;
        height: 20vw;
        filter: grayscale(100%);
        object-fit: cover;

        transition: all .5s;
        
        @media screen and (max-width: 650px){
            height: 40vw;
        }
    }

    @media screen and (max-width: 1050px) {
        height: 20vw;
        max-width: 35vw;
    }

    @media screen and (max-width: 650px){
        height: 100%;
        max-width: 25vw;
    }

`;

export const CardContent = styled.div`
    height: 40%;
    padding: 0 1vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media screen and (max-width: 1050px) {
        pading: 0 3vw;
        line-height: 1.5rem;
    }
`
export const Title = styled.div`
    color: #C63842;
    border-bottom: 1px solid white;
    font-size: 1.7vw;
    padding-bottom: .4vw;
    margin-bottom: 0;
    margin-top: 1vw;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1050px) {
        font-size: 2.7vw;
        margin-top: 2vw;
    }

    @media screen and (max-width: 650px) {
        font-size: 4vw;
        margin-top: 2vw;
    }
`
export const Description = styled.p`
    color: white;

    @media screen and (max-width: 1050px) {
        font-size: 2vw;
        line-height: 3vw;
    }

    @media screen and (max-width: 650px) {
        font-size: 3vw;
        line-height: 5vw;
    }
`

export const Button = styled(motion.button)`
    width: 40%;
    color: white;
    background-color: #C63842;
    border: none;
    border-radius: 7px;
    align-self: center;
    cursor: pointer;
    padding: .7vw;
    transition: all .5s;
    outline: none;

    &:hover {
        background-color: #d9646c;
    }

    @media screen and (max-width: 1050px) {
        padding: 1vw;
        margin-bottom: 2vw;
    }

    @media screen and (max-width: 650px){
        display: none;
    }
`

export const LinkButton = styled(Link)`
    width: 40%;
    color: white;
    background-color: #C63842;
    border: none;
    border-radius: 7px;
    align-self: center;
    cursor: pointer;
    padding: .7vw;
    transition: all .5s;
    outline: none;
    text-decoration: none;
    text-align: center;

    &:hover {
        background-color: #d9646c;
    }

    @media screen and (max-width: 1050px) {
        padding: 1vw;
        margin-bottom: 2vw;
    }

    @media screen and (max-width: 650px){
        display: none;
    }
`

export const Icon = styled(Link)`
    display: none;
    color: #C63842;
    font-size: 5vw;

    @media screen and (max-width: 650px){
        display: block;
        position: absolute;
        top: 85%;
        right: 2vw;
    }
`

export const BestSeller = styled(motion.div)`
    font-weight: bold;
    color: gold;
`