import sample from '../../img/landing-back-video.mp4'
import styled from 'styled-components'

const Video = () => {

    const Media = styled.video`
        position: absolute;
        top: 0;
        left: -25%;
        width: 150%;
        overflow: hidden;

        @media screen and (max-width: 1050px) {
            // height: 60vw;
            // width: auto;
        }

        @media screen and (max-width: 650px){
            display: none;
        }
    `

    return(
        <Media className='videoTag' playsInline autoPlay loop muted>
            <source src={sample} type='video/mp4' />
        </Media>
    )
}

export default Video