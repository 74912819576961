import {useContext} from 'react'
import {UserContext} from '../../../context'
import {useFormik} from 'formik'
import {getFirebase} from '../../../firebase/index-firebase'
import {handleSignUp} from '../../../utils/utils'
import * as Yup from 'yup'
import {
    Container,
    SignupContainer,
    Form,
    Field,
    ErrorMessage
} from './Elements'

const Signup = () => {

    const app = getFirebase()  
    const [currentUser, setCurrentUser] = useContext(UserContext)
    const formik = useFormik ({
        initialValues: {
            email: "",
            password: "",
            password2: ""
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please enter a valid e-mail address").email("Invalid Email"),
            password: Yup.string().required("Please enter a password").min(6, "Please use more than 6 characters"),
            password2: Yup.string().required("Please enter a password").oneOf([Yup.ref('password'), null], 'Passwords must match')
            }),
        onSubmit: values => {
            // setLoading(true);
            // setSigned(true);
            handleSignUp(values, currentUser, app)
            // setError(error)
        },
    });


    return(
        <Container>
            <SignupContainer>
                <Form onSubmit={formik.handleSubmit}>
                    <Field>
                        <input type='email' name='email' {...formik.getFieldProps('email')} placeholder="e-mail"/>
                        <ErrorMessage>
                            {formik.touched.email && formik.errors.email ? ( 
                                    <div>{formik.errors.email}</div>
                                    ) : null}
                        </ErrorMessage>
                    </Field>

                    <Field>
                        <input type='password' name='password' {...formik.getFieldProps('password')} placeholder="password" />
                        <ErrorMessage>
                            {formik.touched.password && formik.errors.password ? ( 
                                    <div>{formik.errors.password}</div>
                                    ) : null}
                        </ErrorMessage>
                    </Field>

                    <Field>
                        <input type='password' name='password2' {...formik.getFieldProps('password2')} placeholder="password check" />
                        <ErrorMessage>
                            {formik.touched.password2 && formik.errors.password2 ? ( 
                                    <div>{formik.errors.password2}</div>
                                    ) : null}
                        </ErrorMessage>
                    </Field>
                    <button type="submit">Submit</button>
                </Form>
            </SignupContainer>
        </Container>
    )
}

export default Signup