import styled from 'styled-components/macro'
import {motion} from 'framer-motion'

export const ContactContainer = styled.section`
    width: 100%;
    height: 50vw;
    padding: 1vw 0;
    display:flex;
    justify-content: center;
    alignt-items: center;
    box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.5);

    @media screen and (max-width: 1050px) {
        height: 80vw;
    }

    @media screen and (max-width: 650px) {
        height: 100vw;
        padding-bottom: 7vw;
    }
`

export const ContactInner = styled.div`
    width: 90%;
    height: 60%;
    display: flex;
    align-self: center;
    justify-content: space-between;

    @media screen and (max-width: 650px) {
        flex-direction: column;
        height: 90%;
    }
`

export const ContactForm = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1050px) {
        width: 100%;
    }

    @media screen and (max-width: 650px) {
        align-items: center;

        p {
            font-size: 1.5vw;
        }

        h2 {
            font-size: 4vw;
        }
    }

`;

export const Form = styled.form`
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    
    @media screen and (max-width: 650px) {
        margin-bottom: 5vw;
    }

    input {
        width: 100%;
        border: none;
        border-bottom: 1px solid #C63842;
        margin: .5rem 0;
        line-height: 2;
        font-size: 1.2rem;
        font-family: 'Raleway', 'Roboto', 'Oxygen', 'Ubuntu', sans-serif;
        outline: none;

        @media screen and (max-width: 650px) {
            font-size: 3vw;
            line-height: 1;
        }
    }

    textarea {
        width: 100%;
        border: none;
        border-bottom: 1px solid #C63842;
        margin: .5rem 0;
        line-height: 2;
        font-size: 1.2rem;
        font-family: 'Raleway', 'Roboto', 'Oxygen', 'Ubuntu', sans-serif;
        outline: none;

        @media screen and (max-width: 650px) {
            font-size: 3vw;
            line-height: 1;
        }
    }

    button {
        color: white;
        background-color: #C63842;
        border: none;
        border-radius: 7px;
        align-self: center;
        cursor: pointer;
        padding: .7vw;
        transition: all .5s;
        outline: none;

        &:hover {
            background-color: #d9646c;
        }
    }
`

export const ShopDetails = styled.div`
    width: 50%;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 1050px) {
        align-items: center;
    }

    @media screen and (max-width: 650px) {
        height: 35vw;
        width: 100%;
    }
`

export const DetailsContainer = styled.div`
    width: 40%;
    background: white;
    box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.25);
    z-index: 10;

    h3 {
        padding-left: .5rem;
        margin-bottom: 1rem;
    }

    span {
        font-size: 3vw;
        color: #C63842;
        transform: translateY(10%);
        margin-right: .5rem;

    }

    ul {
        height: 70%;
        list-style-type: none;
        padding-left: .5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    li {
        display: flex;
        align-items: center;
    }

    @media screen and (max-width: 1050px) {
        width: 100%;
        height: 30vw;
        font-size: 1.5vw;
    }

    @media screen and (max-width: 650px) {
        width: 85%;
        font-size: 3vw;

        h3 {
            font-size: 3vw;
            margin-bottom: 0;
            text-align: center;
            padding-left: 0;
        }

        span {
            margin-right: .1vw;
        }

        ul {
            padding-left: 0;
            margin: 2vw auto;
            height: 65%;
            width: 90%;
            display: flex;
        }
    }
`

export const ContactImage = styled(motion.img)`
    filter: grayscale(100%);
    box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.25);

    @media screen and (max-width: 1050px) {
        display: none;
    }
`


export const InputContainer = styled.div`
    position: relative;
`

export const Error = styled.div`
position: absolute;
top: 0;
left: 0;
color: tomato;

@media screen and (max-width: 650px) {
    font-size: 2vw;
    line-height: 1;
}
`