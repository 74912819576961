import styled from 'styled-components/macro'
import background from '../../img/about.jpg'

const AboutContainer = styled.section`
    position: relative;
    width: 100%;
    height: 38vw;

    @media screen and (max-width: 1050px) {
        height: 55vw;
    }

    @media screen and (max-width: 650px) {
        height: 100vw;
    }
`
const AboutBackground = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 38vw;
    background: url('${background}') no-repeat;
    background-position: 0% 40%;
    background-size: 155%;
    opacity: 0.4;

    @media screen and (max-width: 1050px) {
        background-position: 50% 40%;
        opacity: 0.1;
        height: 55vw;
    }

    @media screen and (max-width: 650px) {
        height: 100vw;
    }
`

const AboutInner = styled.div`
    width: 90%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const AboutLeft = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // text-align: center;
    
    p {
        line-height: 3;
    }

    @media screen and (max-width: 1050px) {
        width: 100%;
        font-size: 2vw;
    }

    @media screen and (max-width: 650px){
        font-size: 3vw;
        line-height: 0;
    }
`

const AboutRight = styled.div`
    width: 45%;

    @media screen and (max-width: 1050px) {
        width: 0;
    }
`

const About = () => {

    return(
        <AboutContainer name="about">
            <AboutBackground />
            <AboutInner>
                <AboutLeft>
                    <h2>ÜBER UNS</h2>
                    <p>Wir bieten Unterhaltung und Spielen auf hohem Niveau.Spielautomaten das ist Kultur, das ist Lebensstil, das ist ein Puls, den man spürt!<br /> Und wer auf dieser Frequenz tickt, trifft bei uns ins Schwarze,<br />denn auf diesem Gebiet der Unterhaltungsautomaten sind wir die Experten."</p>
                </AboutLeft>
                <AboutRight></AboutRight>
            </AboutInner>
        </AboutContainer>
    )
}

export default About