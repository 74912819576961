import React from 'react'
import Landing from './Landing'
import SubLanding from './SubLanding'
import SectionA from './SectionA'
import Contact from '../Contact/index-contact'
import About from './About'

const Home = () => {
    return(
        <>
            <Landing />
            <SubLanding />
            <SectionA />
            <Contact />
            <About />
        </>
    )
}

export default Home