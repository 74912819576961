import {useState, useEffect} from 'react'
import {getFirestore} from '../../firebase/index-firebase'
import {Link} from 'react-scroll'

import {AnimatePresence} from 'framer-motion'
import {
    SidebarContainer,
    SidebarWrapper,
    SidebarMenu,
    SubMenuContainer,
    KatButton,
    KatList,
    KatItem,
    SubMenuLink,
    subMenuVariants,
    SpielerschutzLink
} from './SidebarElements'

const Sidebar = ({isOpen, toggle, isHome}) => {

    const [isActive, setIsActive] = useState(false)

    const db = getFirestore()
    const [categories, setCategories] = useState([])

    useEffect(() => {

        const unsubscribe = () => {

            const array = []

            db.collection('content').doc('categories').get()
            .then(item => {
                item.data().categories.forEach(item => array.push(item))
                setCategories(array)
            })

        }

        return unsubscribe()
        // eslint-disable-next-line
    }, [])

    const handleSubMenu = () => {
        setIsActive(!isActive)
    }

    return (
        <SidebarContainer isOpen={isOpen}>
            <SidebarWrapper>
                <SidebarMenu>
                <SubMenuContainer>
                    <KatButton onClick={handleSubMenu}>Kategorien</KatButton>
                    <AnimatePresence>
                    {
                        isActive &&
                            <KatList
                                variants={subMenuVariants}
                                initial="initial"
                                animate="animate"
                                exit="exit"
                            >
                                {
                                    categories.length > 0 &&
                                    categories.map(category => {
                                            return(
                                                <KatItem key={category.to}>
                                                    <SubMenuLink 
                                                        to={category.to}
                                                        onClick={toggle}
                                                    >
                                                        {category.name}</SubMenuLink>
                                                </KatItem>
                                            )
                                        })
                                }
                                <KatItem>
                                <SpielerschutzLink
                                    to="spielerschutz" 
                                    onClick={toggle}
                                >Spielerschutz</SpielerschutzLink>
                                </KatItem>
                            </KatList> 
                    }
                    </AnimatePresence>
                </SubMenuContainer>
                {/* <li>
                    <RouterLink 
                        style={{textDecoration: 'none', color: '#2F2F2F'}}
                        to="spielerschutz" 
                        onClick={toggle}
                    >Spielerschutz</RouterLink>
                </li> */}
                {
                    isHome &&
                    <>
                        <li>
                            <Link 
                                to="slots" 
                                smooth={true} 
                                offset={-50}
                                onClick={toggle}
                            >Spielen</Link>
                        </li>
                        <li>
                            <Link 
                                to="section-a" 
                                smooth={true}
                                onClick={toggle}
                            >Händler</Link>
                        </li>
                        <li>
                            <Link 
                                to="about" 
                                smooth={true} 
                                offset={-50}
                                onClick={toggle}
                            >Über Uns</Link>
                        </li>
                        <li>
                            <Link 
                                to="contact" 
                                smooth={true} 
                                offset={-50}
                                onClick={toggle}
                            >Kontakt</Link>
                        </li>
                    </>
                }


                    {/* {
                        isHome ? (
                            <>
                                <SubMenuContainer>
                                    <KatButton onClick={handleSubMenu}>Kategorien</KatButton>
                                    <AnimatePresence>
                                    {
                                        isActive &&
                                            <KatList
                                                variants={subMenuVariants}
                                                initial="initial"
                                                animate="animate"
                                                exit="exit"
                                            >
                                                {
                                                    categories.length > 0 &&
                                                    categories.map(category => {
                                                            return(
                                                                <KatItem key={category.to}>
                                                                    <SubMenuLink 
                                                                        to={category.to}
                                                                        onClick={toggle}
                                                                    >
                                                                        {category.name}</SubMenuLink>
                                                                </KatItem>
                                                            )
                                                        })
                                                }
                                            </KatList> 
                                    }
                                    </AnimatePresence>
                                </SubMenuContainer>
                                <li>
                                    <Link 
                                        to="slots" 
                                        smooth={true} 
                                        offset={-50}
                                        onClick={toggle}
                                    >Spielen</Link>
                                </li>
                                <li>
                                    <Link 
                                        to="section-a" 
                                        smooth={true}
                                        onClick={toggle}
                                    >Händler</Link>
                                </li>
                                <li>
                                    <Link 
                                        to="about" 
                                        smooth={true} 
                                        offset={-50}
                                        onClick={toggle}
                                    >Über Uns</Link>
                                </li>
                                <li>
                                    <Link 
                                        to="contact" 
                                        smooth={true} 
                                        offset={-50}
                                        onClick={toggle}
                                    >Kontakt</Link>
                                </li>
                            </>
                        ) :
                        <SubMenuContainer>
                                    <KatButton onClick={handleSubMenu}>Kategorien</KatButton>
                                        <KatList
                                            variants={subMenuVariants}
                                            initial="initial"
                                            animate="animate"
                                            exit="exit"
                                        >
                                            {
                                                categories.length > 0 &&
                                                categories.map(category => {
                                                        return(
                                                            <KatItem key={category.to}>
                                                                <SubMenuLink 
                                                                    to={category.to}
                                                                    onClick={toggle}
                                                                >
                                                                {category.name}</SubMenuLink>
                                                            </KatItem>
                                                        )
                                                    })
                                            }
                                            </KatList> 
                                </SubMenuContainer>
                    } */}
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar;