import {useContext} from 'react'
import {UserContext} from '../../../context'
import styled from 'styled-components'
import {motion} from 'framer-motion'
import {useFormik} from 'formik'
import {getFirebase} from '../../../firebase/index-firebase'
import {handleSignUp} from '../../../utils/utils'
import * as Yup from 'yup'

const Container = styled(motion.div)`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
`

const LoginContainer = styled(motion.div)`
    width: 30vw;
    padding: 2vw;
    background: #202020;
`

const Login = () => {

    const app = getFirebase()
    const [currentUser, setCurrentUser] = useContext(UserContext)
    const formik = useFormik ({
        initialValues: {
            email: "",
            password: "",
            password2: ""
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please enter a valid e-mail address").email("Invalid Email"),
            password: Yup.string().required("Please enter a password").min(6, "Please use more than 6 characters"),
            password2: Yup.string().required("Please enter a password").oneOf([Yup.ref('password'), null], 'Passwords must match')
            }),
        onSubmit: values => {
            // setLoading(true);
            // setSigned(true);
            let error = handleSignUp(values, currentUser, app)
            // setError(error)
        },
    });


    return(
        <Container>
            <LoginContainer>

            </LoginContainer>
        </Container>
    )
}

export default Login