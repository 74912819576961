import {useEffect} from 'react'
import styled from 'styled-components/macro'
import {motion, useAnimation} from 'framer-motion'
import {useInView} from 'react-intersection-observer'
import sectionIcon from '../../img/section-icon.png'

const SlotContainer = styled.div`
    width: 20vw;
    margin: 5vw auto;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1050px) {
        display: none;
    }
`

const ImageContainer = styled.div`
    width: 5vw;
    max-height: 7vw;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
        
    img {
        width: 3vw;
        margin: 1vw;

        @media screen and (max-width: 1050px) {
            width: 5vw;
        }
    }
`

const SlotAnimation = () => {

    const {ref, inView} = useInView({threshold: 0.1})
    const firstAnimation = useAnimation()
    const secondAnimation = useAnimation()
    const thirdAnimation = useAnimation()

    useEffect(() => {

        if(inView) {

            firstAnimation.start({
                y: -127,
                transition: {
                    duration: .5
                }
            })
    
            secondAnimation.start({
                y: 130,
                transition: {
                    duration: .8
                }
            })
    
            thirdAnimation.start({
                y: -127,
                transition: {
                    duration: .7
                }
            })
        }

        if(!inView) {

            firstAnimation.start({
                y: 105,
                transition: {
                    duration: .5
                }
            })
    
            secondAnimation.start({
                y: -127,
                transition: {
                    duration: .3
                }
            })
    
            thirdAnimation.start({
                y: 105,
                transition: {
                    duration: .7
                }
            })
        }

    // eslint-disable-next-line
    }, [inView])

    return(
        <SlotContainer ref={ref}>
            <ImageContainer>
                <motion.img animate={firstAnimation} initial={{y:105}} src={sectionIcon} alt="slot machine icon"/>
                <motion.img animate={firstAnimation} initial={{y:105}} src={sectionIcon} alt="slot machine icon"/>
                <motion.img animate={firstAnimation} initial={{y:105}} src={sectionIcon} alt="slot machine icon"/>
                <motion.img animate={firstAnimation} initial={{y:105}} src={sectionIcon} alt="slot machine icon"/>
                <motion.img animate={firstAnimation} initial={{y:105}} src={sectionIcon} alt="slot machine icon"/>
            </ImageContainer>
            <ImageContainer>
                <motion.img animate={secondAnimation} initial={{y:-127}} src={sectionIcon} alt="slot machine icon"/>
                <motion.img animate={secondAnimation} initial={{y:-127}} src={sectionIcon} alt="slot machine icon"/>
                <motion.img animate={secondAnimation} initial={{y:-127}} src={sectionIcon} alt="slot machine icon"/>
                <motion.img animate={secondAnimation} initial={{y:-127}} src={sectionIcon} alt="slot machine icon"/>
                <motion.img animate={secondAnimation} initial={{y:-127}} src={sectionIcon} alt="slot machine icon"/>
            </ImageContainer>
            <ImageContainer>
                <motion.img animate={thirdAnimation} initial={{y:105}} src={sectionIcon} alt="slot machine icon"/>
                <motion.img animate={thirdAnimation} initial={{y:105}} src={sectionIcon} alt="slot machine icon"/>
                <motion.img animate={thirdAnimation} initial={{y:105}} src={sectionIcon} alt="slot machine icon"/>
                <motion.img animate={thirdAnimation} initial={{y:105}} src={sectionIcon} alt="slot machine icon"/>
                <motion.img animate={thirdAnimation} initial={{y:105}} src={sectionIcon} alt="slot machine icon"/>
            </ImageContainer>
        </SlotContainer>
    )
}

export default SlotAnimation