import {useState, useEffect} from 'react'
import {getFirestore} from '../../firebase/index-firebase'
import Card from '../Card/index-card'
import CardAlt from '../Card/index-card-alt'
import MachinesImage from '../../img/maquinas500px.png'
import {
    Container,
    Inner, 
    SubInner,
    MachinesContainer,
    MachinesInner,
    MachinesImageContainer
} from './SubLandingElements'

const SubLanding = () => {

    const db = getFirestore()
    const [products, setProducts] = useState([])
    const [bestSellers, setBestSellers] = useState([])

    useEffect(() => {

        db.collection('products').where("category", "==", "geschicklichkeitsautomaten").get()
        .then(items => {
            
            const array = []
            const array2 = []

            items.forEach(item => {
                
                if(item.data().isFlippable) {
                    
                    array2.push(item.data())
                    
                } else {
                    
                    array.push(item.data())

                }

            })

            setProducts(array)
            setBestSellers(array2)

        })
    // eslint-disable-next-line
    }, [])

    return(
        <>
            <Container name="slots">
                <Inner>
                    <SubInner>
                        {
                            bestSellers.length > 0 &&
                            bestSellers.map((item, index) => {
                                return(
                                    <Card product={item} key={index} />
                                )
                            })
                        }
                    </SubInner>
                </Inner>
                <Inner>
                    <SubInner>
                        {
                            products.length > 0 &&
                            products.map((item, index) => {
                                return(
                                    <CardAlt product={item} key={index} />
                                )
                            })
                        }
                    </SubInner>
                </Inner>
            </Container>
            <MachinesContainer>
                <MachinesInner>
                    <h2>Geschicklichkeitsautomaten</h2>
                    <MachinesImageContainer src={MachinesImage} alt="slots" />
                </MachinesInner>
            </MachinesContainer>
        </>    
    )
}

export default SubLanding