import {useState, useEffect} from 'react'
import {BrowserRouter as Router, Switch, Route, useLocation} from 'react-router-dom'
import {UserContext} from './context'
import MainNav from './components/Nav'
import Home from './components/Home'
import Footer from './components/Footer'
import ProductPage from './components/ProductPage/index-product'
import Users from './components/Users'
import MobileSpielerschutz from './components/MobileSpielerschutz/index-spielerschutz'


function App() {

  const {pathname} = useLocation()
  const [currentUser, setCurrentUser] = useState({})
  const [isUsers, setIsUsers] = useState(false)

  useEffect(() => {

    const unsubscribe = () => {
      window.scrollTo(0,0)
    }

    return unsubscribe()

  }, [pathname])

  useEffect(() => {

    const unsubscribe = () => {
      pathname === '/users' && setIsUsers(true)
    }

    return unsubscribe()

  }, [pathname])

  return (
    <UserContext.Provider value={[currentUser, setCurrentUser]}>
      <Router>
        {
          !isUsers &&
          <MainNav />
        }
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/spielerschutz">
            <MobileSpielerschutz />
          </Route>
          <Route path="/users">
            <Users />
          </Route>
          <Route path="/:product">
            <ProductPage /> 
          </Route>
        </Switch>
        {
          !isUsers &&
          <Footer />
        }
      </ Router>
    </UserContext.Provider>
  );
}

export default App;
